import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { Imports } from 'services/imports';
import { Notifier, ContentFullHeight } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class ImportFile {
    static inject = [Security, I18n, Imports, Notifier, NewInstance.of(ValidationController)];
    _i18n;
    _imports;
    _notifier;
    _validationController;

    @bindable type;

    step = 'upload';
    _fileDefinitionId;
    fileDefinitionName;
    fileDefinitionSource;
    fileDefinitionSupplier;
    columns;
    _fileUploadPath;
    typeFilters = [{ value: '', keys: ['name'] }];
    dateFormats = ['MM/DD/YYYY', 'MM/DD/YY', 'M/D/YYYY', 'M/D/YY', 'YYYY-MM-DD', 'YYYY-M-D', 'DD/MM/YYYY', 'D/M/YYYY', 'YYYY-MM-DDTHH:mm:ss.000Z'];
    importTypeName;

    constructor(security, i18n, imports, notifier, validationController) {
        this._i18n = i18n;
        this._imports = imports;
        this._notifier = notifier;
        this.agentId = security.authenticatedMemberId;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('fileDefinitionName').required().when(x => x.step === 'define-import')
            .ensure('fileDefinitionSource').required().when(x => x.step === 'define-import')
            .ensure('fileDefinitionSupplier').required().when(x => x.step === 'define-import')
            .on(this);
    }

    attached() {
        this.cancel();
        this._fileUploadPath = undefined;

        this._contentFullHeight = new ContentFullHeight();
        this._contentFullHeight.initialize(this.fullHeightEl);
        this.importTypeName = this._i18n.tr(`import-type-${this.type}-title`);
    }

    detached() {
        this._contentFullHeight.dispose();
    }

    cancel() {
        this.step = 'upload';
        this.columns = [];
        this.columnDataTypes = [];
        this._fileUploadPath = undefined;
        this.column = undefined;
        this.columnIndex = undefined;
        this.importErrors = undefined;
        this.importCount = undefined;
        this.mappingColumn = false;
    }

    onFileDefinitionNotFound(data) {
        this.step = 'define-import';
        this.columns = data.file.columns;
        this.columnDataTypes = data.file.columnDataTypes;
        this.columnDataTypes.push({ dataType: 'custom', isDecimal: false, name: '', nameKey: '', importKey: '', importType: 'Bag' });
        this._fileUploadPath = data.file.filePath;
    }

    startMappingColumn(column, index) {
        this.column = column;
        this.typeFilters[0].value = column.columnHeading;
        this.columnIndex = index;
        this.mappingColumn = true;
    }

    ignoreColumn(column, index) {
        this.startMappingColumn(column, index);
        this.selectMapping({ type: 'misc', name: '-' });
    }

    selectMapping(dataType) {
        if (dataType.dataType === 'custom') {
            if (!this.column.customColumn) {
		        this._notifier.error('enter-custom-column-name');
                return;
            }
            const clonedDataType = JSON.parse(JSON.stringify(dataType));
            clonedDataType.importKey = this.column.customColumn;
            clonedDataType.name = this.column.customColumn;
            this.column.mapped = clonedDataType;
        } else {
            this.column.mapped = dataType;
        }
        this.typeFilters[0].value = '';
        if (!isNaN(this.columnIndex) && this.columns.length > this.columnIndex + 1) {
            const startMappingIndex = this.columnIndex + 1;
            this.startMappingColumn(this.columns[startMappingIndex], startMappingIndex);
        } else {
            this.cancelColumnMapping();
        }
    }

    cancelColumnMapping() {
        this.column = undefined;
        this.columnIndex = undefined;
        this.mappingColumn = false;
    }

	async saveDefinition() {
	    if (this.processing) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const columnNotMapped = this.columns.find(x => !x.mapped);
            if (columnNotMapped) {
		        this._notifier.error('save-file-definition-map-columns-error');
                return;
            }

            this.processing = true;
	        const result = await this._imports.saveDefinition(this._fileDefinitionId, this._fileUploadPath, this.fileDefinitionName, this.type, this.fileDefinitionSource, this.fileDefinitionSupplier, this.columns, this.columnDataTypes);
            if (result.succeeded && result.model && !result.model.errors.length) {
                this.onFileUploadSuccess(result.model);
                return;
            }
            // An error occurred
            this._notifier.error(result.message);
	    } catch (err) {
            console.log(err);
        } finally {
            this.processing = false;
        }
	}

    onFileUploadSuccess(data) {
        try {
            this.step = 'results';
            this.importErrors = data.errors;
            switch (this.type) {
                case 'lead': this.importCount = data.leads.length; break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    startNewImport() {
        this.fileDefinitionName;
        this.fileDefinitionSource;
        this.fileDefinitionSupplier;
        this.cancel();
    }
}